
import { Component, Prop, Vue } from "vue-property-decorator";
import { Editor } from "@tiptap/vue-2";
import EditorBarButton from "@/components/richTextEditor/EditorBarButton.vue";

/**
 * EditorBar basically a menu where rich text editor options are displayed
 */
@Component({
  name: "EditorBar",
  components: { EditorBarButton },
})
export default class EditorBar extends Vue {
  // The tiptap editor instance
  @Prop({ type: Editor, required: true })
  editor!: Editor;
  // Available menu options
  menuOption = [
    {
      title: "bold",
      action: (): boolean => this.editor.chain().focus().toggleBold().run(),
      isActive: (): boolean => this.editor.isActive("bold"),
      icon: "mdi-format-bold",
    },
    {
      title: "italic",
      action: (): boolean => this.editor.chain().focus().toggleItalic().run(),
      isActive: (): boolean => this.editor.isActive("italic"),
      icon: "mdi-format-italic",
    },
    {
      title: "underline",
      action: (): boolean => this.editor.chain().focus().setParagraph().run(),
      icon: "mdi-format-paragraph",
    },
    {
      title: "underline",
      action: (): boolean => this.editor.chain().focus().undo().run(),
      icon: "mdi-undo",
    },
    {
      title: "underline",
      action: (): boolean => this.editor.chain().focus().redo().run(),
      icon: "mdi-redo",
    },
  ];
}
