
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { UFliterals } from "@/utils/literals";
import { IActivity } from "@/schemas/IActivity";
import draggable from "vuedraggable";
import { IItem } from "@/schemas/IItem";
import {
  between,
  maxLength,
  minLength,
  numeric,
  required,
} from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import RichTextEditor from "@/components/richTextEditor/RichTextEditor.vue";
import { findFirstGap } from "@/utils";
import { ISlide } from "@/schemas/ISlide";
import { ISlideElement } from "@/schemas/ISlideElement";
import { apiUrl } from "@/env";
import { ActivityType, disciplineType } from "@/schemas/Enums";
import { UFFormValidationMessage } from "@/utils/literals/forms";
import { infoTooltips } from "@/utils/literals/admin";

@Component({
  name: "ActivityForm",
  computed: {
    ActivityType() {
      return ActivityType;
    },
  },
  components: { RichTextEditor, draggable },
  mixins: [validationMixin],
  validations: {
    activity: {
      title: {
        required,
        maxLength: maxLength(100),
        minLength: minLength(5),
      },
      player_number: {
        required,
        numeric,
        maxLength: maxLength(1),
        minLength: minLength(1),
        between: between(0, 8),
      },
      type: { required },
      discipline: { required },
      guideline: {
        required,
        maxLength: maxLength(2000),
        minLength: minLength(50),
      },
      single_session_time: {
        required,
        numeric,
        maxLength: maxLength(3),
        minLength: minLength(1),
        between: between(1, 999),
      },
      group_session_time: {
        required,
        numeric,
        maxLength: maxLength(3),
        minLength: minLength(1),
        between: between(1, 999),
      },
      items: {
        $each: {
          label: {
            required,
            maxLength: maxLength(200),
            minLength: minLength(5),
          },
          expected_rank: {
            required,
            between: between(1, 99),
          },
        },
      },
    },
    slides: {
      $each: {
        order_number: {
          required,
        },
        elements: {
          $each: {
            content: { required },
            type: { required },
            file: {},
            id: {},
          },
        },
      },
    },
  },
})
export default class ActivityForm extends Vue {
  @Prop({ type: String, required: false, default: UFliterals.create })
  readonly submitText: string | undefined;
  @Prop({ type: Function, required: false })
  readonly dataHandler: CallableFunction | undefined;
  @Prop({ type: Boolean, required: false, default: false })
  readonly readOnly: boolean | undefined;
  @Prop({ type: Object, required: false })
  readonly initialActivity: IActivity | undefined;

  @Watch("initialActivity")
  initialActivityChanged(val: IActivity): void {
    if (Object.keys(this.initialActivity).length) {
      this.creation=false
    }
    else{
      this.creation=true
    }
    this.renewActivity(val);
  }

  private activity: IActivity = this.getDefaultActivity();
  private slides: ISlide[] = [];
  lang = 'fr'

  private imageRules = [
    (value: { size: number }) =>
      !value || value.size < 2000000 || UFFormValidationMessage.sizeMax("2 Mo"),
  ];

  data(): any {
    return {
      dialogClose: false,
      dialogClose2: false,
      dialog: "",
      creation: true,
      // userList: [],
    }
  }



  created(): void {
    this.creation=true
    if (this.initialActivity) {
      this.renewActivity(this.initialActivity);
      this.creation=false
    } else {
      this.activity = this.getDefaultActivity();
    }
  }

  getUFLabel(key: string): string {
    return UFliterals[key];
  }

  getTooltipContent(key: string): string {
    return infoTooltips[this.lang][key];
  }

  getDefaultActivity(): IActivity {
    return {
      title: "",
      guideline: "",
      type: ActivityType.Ranking,
      player_number: 5,
      single_session_time: 15,
      group_session_time: 25,
      items: [],
    };
  }

  get typeSelectItems(): Record<string, any>[] {
    let selectValues: Record<string, any>[] = [];
    for (const value of Object.values(ActivityType)) {
      selectValues.push({
        text: this.getUFLabel(value),
        value: value,
      });
    }
    return selectValues;
  }

  get disciplineSelectItems(): Record<string, any>[] {
    let selectValues: Record<string, any>[] = [];
    for (const value of Object.values(disciplineType)) {
      selectValues.push({
        text: this.getUFLabel(value),
        value: value,
      });
    }
    return selectValues;
  }

  get titleErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.activity.title?.$dirty) return errors;
    !this.$v.activity.title.minLength &&
      errors.push(UFFormValidationMessage.minLengthChar(5));
    !this.$v.activity.title.maxLength &&
      errors.push(UFFormValidationMessage.maxLengthChar(100));
    !this.$v.activity.title.required &&
      errors.push(UFFormValidationMessage.required);
    return errors;
  }

  get typeErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.activity.type?.$dirty) return errors;
    !this.$v.activity.type.required &&
      errors.push(UFFormValidationMessage.required);
    return errors;
  }

  get disciplineErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.activity.discipline?.$dirty) return errors;
    !this.$v.activity.discipline.required &&
    errors.push(UFFormValidationMessage.required);
    return errors;
  }

  get playerNumberErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.activity.player_number?.$dirty) return errors;
    !this.$v.activity.player_number.minLength &&
      errors.push(UFFormValidationMessage.minLengthNum(1));
    !this.$v.activity.player_number.maxLength &&
      errors.push(UFFormValidationMessage.maxLengthNum(1));
    !this.$v.activity.player_number.required &&
      errors.push(UFFormValidationMessage.required);
    return errors;
  }

  get guidelineErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.activity.guideline?.$dirty) return errors;
    !this.$v.activity.guideline.minLength &&
      errors.push(UFFormValidationMessage.minLengthChar(50));
    !this.$v.activity.guideline.maxLength &&
      errors.push(UFFormValidationMessage.maxLengthChar(2000));
    !this.$v.activity.guideline.required &&
      errors.push(UFFormValidationMessage.required);
    return errors;
  }

  get singleSessionTimeErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.activity.single_session_time?.$dirty) return errors;
    !this.$v.activity.single_session_time.minLength &&
      errors.push(UFFormValidationMessage.minLengthNum(1));
    !this.$v.activity.single_session_time.maxLength &&
      errors.push(UFFormValidationMessage.maxLengthNum(3));
    !this.$v.activity.single_session_time.required &&
      errors.push(UFFormValidationMessage.required);
    !this.$v.activity.single_session_time.between &&
    errors.push(UFFormValidationMessage.between(1, 999));
    return errors;
  }

  get groupSessionTimeErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.activity.group_session_time?.$dirty) return errors;
    !this.$v.activity.group_session_time.minLength &&
      errors.push(UFFormValidationMessage.minLengthNum(1));
    !this.$v.activity.group_session_time.maxLength &&
      errors.push(UFFormValidationMessage.maxLengthNum(3));
    !this.$v.activity.group_session_time.required &&
      errors.push(UFFormValidationMessage.required);
    !this.$v.activity.group_session_time.between &&
    errors.push(UFFormValidationMessage.between(1, 999));
    return errors;
  }

  getItemLabelErrors(index: number): string[] {
    const errors: string[] = [];
    if (!this.$v.activity.items?.$each.$iter[index]?.label.$dirty)
      return errors;
    !this.$v.activity.items.$each.$iter[index]?.label.minLength &&
      errors.push(UFFormValidationMessage.minLengthChar(5));
    !this.$v.activity.items.$each.$iter[index]?.label.maxLength &&
      errors.push(UFFormValidationMessage.maxLengthChar(200));
    !this.$v.activity.items.$each.$iter[index]?.label.required &&
      errors.push(UFFormValidationMessage.required);
    return errors;
  }

  getItemExpectedRankErrors(index: number): string[] {
    const errors: string[] = [];
    if (!this.$v.activity.items?.$each.$iter[index]?.expected_rank.$dirty)
      return errors;
    let items: IItem[] = JSON.parse(JSON.stringify(this.activity.items));
    let current_item: IItem[] = items.splice(index, 1);
    let itemsRanks: number[] = items.map((item: IItem) =>
      typeof item.expected_rank === "string"
        ? parseInt(item.expected_rank)
        : item.expected_rank
    );
    itemsRanks.includes(
      typeof current_item[0].expected_rank === "string"
        ? parseInt(current_item[0].expected_rank)
        : current_item[0].expected_rank
    ) && errors.push(UFFormValidationMessage.rankAlreadyTaken);
    !this.$v.activity.items.$each.$iter[index]?.expected_rank.required &&
      errors.push(UFFormValidationMessage.required);
    return errors;
  }

  addItem(): void {
    let expectedRank: number;
    let alreadyTakenRanks = this.activity.items
      ?.map((item: IItem) =>
        typeof item.expected_rank === "string"
          ? parseInt(item.expected_rank)
          : item.expected_rank
      )
      .sort((a, b) => a - b);
    if (alreadyTakenRanks) {
      expectedRank = findFirstGap(alreadyTakenRanks);
    } else {
      expectedRank = 1;
    }

    let newItem: IItem = {
      label: "",
      expected_rank: expectedRank,
    };
    if (!this.activity.items) {
      Vue.set(this.activity, "items", [newItem]);
    } else {
      Vue.set(this.activity.items, this.activity.items?.length, newItem);
    }
  }

  addSlide(): void {
    let newSlide: ISlide = {
      order_number: 0,
      elements: [],
    };
    newSlide.order_number = this.slides.length;
    Vue.set(this.slides, this.slides?.length, newSlide);
  }

  addElement(slideIndex: number, elementType: string): void {
    if (this.slides[slideIndex]) {
      let newElement: ISlideElement = {
        type: elementType,
        content: "",
        order_number: this.slides[slideIndex].elements?.length || 0,
      };
      if (this.slides[slideIndex].elements) {
        Vue.set(
          this.slides[slideIndex].elements,
          this.slides[slideIndex].elements?.length,
          newElement
        );
      } else {
        Vue.set(this.slides[slideIndex], "elements", [newElement]);
      }
    }
  }
  removeSlide(index: number): void {
    let slideToRemove = this.slides[index];
    if (this.slides && slideToRemove) {
      if (slideToRemove.id) {
        this.$store.dispatch("slide/deleteSlide", {
          id: slideToRemove.id,
          next: (isDeleted: boolean) => {
            if (isDeleted) {
              this.slides.splice(index, 1);
            }
          },
        });
      } else {
        this.slides.splice(index, 1);
      }
    }
  }
  removeSlideElement(index: number, slideIndex: number): void {
    if (this.slides && this.slides[slideIndex]) {
      let elementToRemove = this.slides[slideIndex].elements
        ? this.slides[slideIndex].elements[index]
        : null;
      if (elementToRemove) {
        if (elementToRemove.id) {
          // The element is already saved, so we'll have to remove it
          if (elementToRemove.type === "img" && elementToRemove.content) {
            this.$store.dispatch("slide/deleteSlideFile", {
              fileName: elementToRemove.content,
              slideId: this.slides[slideIndex].id,
            });
          }
          this.$store.dispatch("slide/deleteSlideElement", {
            id: elementToRemove.id,
            next: (isDeleted: boolean) => {
              if (isDeleted) {
                this.slides[slideIndex].elements?.splice(index, 1);
              }
            },
          });
        } else {
          if (elementToRemove.type === "img" && elementToRemove.content) {
            this.$store.dispatch("slide/deleteSlideFile", {
              fileName: elementToRemove.content,
            });
          }
          this.slides[slideIndex].elements.splice(index, 1);
        }
      }
    }
  }

  removeItem(index: number): void {
    if (this.activity.items) {
      let elementToRemove = this.activity.items[index];
      if (elementToRemove) {
        if (elementToRemove.id) {
          // The element is already saved, so we'll have to remove it
          this.$store.dispatch("item/deleteItem", {
            id: elementToRemove.id,
            next: () => {
              this.activity.items?.splice(index, 1);
            },
          });
        } else {
          this.activity.items.splice(index, 1);
        }
      }
    }
  }

  handleSubmit(): void {
    this.$v.$touch();
    this.activity.type='ranking'
    console.log(this.activity.discipline)
    if (
      !this.$v.$invalid && this.activity.items
        ? !this.hasDouble(this.activity.items)
        : false
    ) {
      if (this.dataHandler) {
        this.slides.forEach((slide) =>
          slide.elements.map(
            (element, index) => (element.order_number = index + 1)
          )
        );
        this.activity.slides = [...this.slides];
        this.dataHandler(this.activity);
      }
    }
  }

  clear(): void {
    this.$v.$reset();
    this.activity = this.getDefaultActivity();
    this.slides = [];
  }

  onFileChange(file: File, elIndex: number, slideIndex: number): void {
    if (file) {
      if (this.slides[slideIndex] && this.slides[slideIndex].elements) {
        Vue.set(
          this.slides[slideIndex].elements[elIndex],
          "content",
          file.name
        );
      }
      this.$store.dispatch("slide/uploadFile", { file });
    } else {
      this.$store.dispatch("slide/deleteSlideFile", {
        fileName: this.slides[slideIndex].elements[elIndex].content,
      });
    }
  }

  backgroundImgChange(file: File): void {
    if (file) {
      Vue.set(this.activity, "background_img", file.name);
      this.$store.dispatch("activity/uploadBackground", { file });
    }
    // else {
    // this.$store.dispatch("activity/deleteBackground", {
    //   id: this.activity.id || "",
    // });
    // }
  }

  hasDouble(list: IItem[]): boolean {
    for (let lengthKey = 0; lengthKey <= list.length; lengthKey++) {
      if (list.filter((item) => item.expected_rank == lengthKey).length > 1)
        return true;
    }
    return false;
  }

  renewActivity(value: IActivity): void {
    if (Object.keys(value).length) {
      this.activity = Object.assign({}, value);
      let slides = value.slides;
      this.slides = [];
      if (slides) {
        slides = slides?.sort((a, b) => a.order_number - b.order_number);
        slides.map(
          (slide) =>
            (slide.elements = slide.elements?.sort(
              (a, b) => a.order_number - b.order_number
            ))
        );
        this.slides = slides;
      }
    } else {
      this.activity = Object.assign({}, this.getDefaultActivity());
      this.slides = [];
    }
  }

  updateGuideline(guideline: string): void {
    this.$v.activity.guideline?.$touch();
    this.activity.guideline = guideline;
  }

  getFileLink(slideId: string, fileName: string): string {
    return `${apiUrl}/slides/${slideId}/files/${fileName}`;
  }
}
