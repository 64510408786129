
import { Component, Prop, Vue } from "vue-property-decorator";

/**
 * Rich text editor button component
 */
@Component({
  name: "EditorBarButton",
})
export default class EditorBarButton extends Vue {
  // Action populated when EditorBarButton is clicked
  @Prop({ type: Function, required: true })
  action!: CallableFunction;
  // Title to bind to html title attribute of the button
  @Prop({ type: String, required: true })
  title!: string;
  // Name of the icon to display as button text
  @Prop({ type: String, required: true })
  icon!: string;
  // Boolean indicates if the option is active on the selection
  @Prop({ type: Function, required: false, default: () => false })
  isActive: CallableFunction | undefined;
}
