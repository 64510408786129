export const infoTooltips: Record<string, Record<string, string>> = {
  fr: {
    guideline:
      "Créer la consigne que les participants doivent respecter" +
      " lors de l’activité. La consigne sera affichée de manière permanente " +
      "lors de l'activité.",
    intro:
      "Les éléments de contextualisation de l’activité sont à remplir ici. " +
      "Ils s’afficheront de manière permanente lors de l’activité des " +
      "participants.",
    items: "",
    feedback:
      "Concerne le retour que les participants recevront à l’issue de l’activité\n" +
      "réalisée. Vous pouvez sélectionner leur performance lié à la tâche ainsi que le nombre de\n" +
      "message envoyé au sein du groupe",
    debriefing:
      "Permet de faire le point sur l’activité réalisée, avec un retour sur les\n" +
      "impressions des participants ainsi que l’ajout d’exercice d'entraînement.",
    question:
      "Vous pouvez choisir de créer des questions qui permettront aux participants\n" +
      "de s’améliorer dans leur travail collaboratif",
    attendanceType:
      "Vous pouvez paramétrer le type de participation ainsi que\n" +
      "la durée de vos sessions.",
    tips:
      "Ce sont les conseils donnés aux participants lors de " +
      "l’activité afin qu’ils puissent s’améliorer dans leur travail " +
      "collaboratif.",
  },
  en: {
    guideline:
      "Create the instructions that participants must follow during the " +
      "activity. The guidelines will be permanently displayed during " +
      "the activity.",
    intro:
      "The context elements for the activity should be filled in here. " +
      "They will be displayed permanently during the participants' activity.",
    items: "",
    feedback: "This refers to the feedback that participants will receive at the end " +
        "of the activity. You can select their performance on the task and the number " +
        "of messages sent within the group.",
    debriefing: "Allows you to take stock of the activity carried out, with feedback on participants' " +
        "impressions and the addition of training exercises.",
    question: "You can choose to create questions to help participants improve their collaborative work.",
    attendanceType:
      "You can set the attendance type and duration of your sessions.",
    tips:
      "This is the advice given to participants during the activity so that they can improve their collaborative work.",
  },
};
