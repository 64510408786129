
import { Component, Prop, Vue } from "vue-property-decorator";
import { FeedbackType } from "@/schemas/Enums";
import { UFliterals } from "@/utils/literals";

@Component({
  name: "FeedbackSettingForm",
  emit: ["feedbackDetailsUpdated"],
  computed: {
    FeedbackType() {
      return FeedbackType;
    },
  },
})
export default class FeedbackSettingForm extends Vue {
  @Prop({
    required: false,
    default: [
      {
        type: FeedbackType.Performance,
        display_feedback_message: true,
      },
      { type: FeedbackType.Participation, display_feedback_message: true },
    ],
  })
  // Default feedback values
  feedbacks!: {
    type: FeedbackType;
    display_feedback_message: boolean;
    display_score?: boolean;
    display_indiv_scores?: boolean;
    display_group_score?: boolean;
  }[];

  @Prop({ required: false, default: false, type: Boolean })
  readOnly!: boolean;

  updated(): void {
    this.$emit("feedbackDetailsUpdated", this.feedbacks);
  }

  deleteFb(index: number): void {
    this.feedbacks.splice(index, 1);
  }

  addFb(): void {
    this.feedbacks.push({
      type: this.feedbacks.length
        ? this.feedbacks[0].type === FeedbackType.Performance
          ? FeedbackType.Participation
          : FeedbackType.Performance
        : FeedbackType.Performance,
      display_feedback_message: true,
    });
  }

  getUFLabel(key: string): string {
    return UFliterals[key];
  }
}
