
import { Component, Prop, Vue } from "vue-property-decorator";
import { IIconActionCard } from "@/schemas/IIconActionCard";
import { assetsUrl } from "@/env";

/**
 * IconActionCards Component
 */
@Component({
  name: "IconActionCards",
})
export default class IconActionCards extends Vue {
  // List of cards to be displayed
  @Prop({ required: true })
  cards!: IIconActionCard[];
  @Prop({ required: false, type: Number })
  width: number | undefined;

  /**
   * Retrieves image relative path
   * @param slug
   */
  getImageSrc(slug: string): string {
    return `${assetsUrl}/svg/${slug}`;
  }
}
