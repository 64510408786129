
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import CharacterCount from "@tiptap/extension-character-count";
import EditorBar from "@/components/richTextEditor/EditorBar.vue";

/**
 * Rich text editor
 */
@Component({
  name: "RichTextEditorEditor",
  components: { EditorBar, EditorContent },
})
export default class RichTextEditorEditor extends Vue {
  // Identifier to bind to native html id attribute
  @Prop({ type: String, required: false })
  id: string | undefined;
  // Value that is edited by this text editor
  @Prop({ type: String, required: true })
  value!: string;
  @Prop({ type: Boolean, required: false, default: false })
  readonly readOnly: boolean | undefined;
  @Prop({ type: Number, required: false })
  readonly maxLength: number | undefined;

  // Tiptap editor instance
  private editor: Editor | null = null;

  @Watch("value")
  valueChanged(value: string): void {
    const isSame = this.editor?.getHTML() === value;
    if (!isSame) {
      this.editor?.commands.setContent(this.value, false);
    }
  }

  // Return a valid dict to bind native html attributes
  get binding(): { id?: string } {
    if (this.id) {
      return { id: this.id };
    }
    return {};
  }

  get wordsCount(): number {
    return this.editor?.storage.characterCount.words();
  }
  get charCount(): number {
    return this.editor?.storage.characterCount.characters();
  }

  mounted(): void {
    this.editor = new Editor({
      extensions: [
        StarterKit,
        CharacterCount.configure({
          limit: this.maxLength,
          mode: "textSize",
        }),
      ],
      editable: !this.readOnly,
      content: this.value,
      onUpdate: () => {
        this.$emit("input", this.editor?.getHTML());
      },
    });
  }

  beforeDestroy(): void {
    this.editor?.destroy();
  }
}
