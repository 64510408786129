
import { Component, Vue } from "vue-property-decorator";

import { mapGetters, mapState } from "vuex";
import { UFliterals } from "@/utils/literals";
import SequenceList from "@/components/sequence/SequenceList.vue";
import IconActionCards from "@/components/cta/IconActionCards.vue";
import { IIconActionCard } from "@/schemas/IIconActionCard";
import AdminBoard from "@/components/admin/AdminBoard.vue";
import HomeTitle from "@/components/identity/HomeTitle.vue";
import Logo from "@/components/identity/Logo.vue";

@Component({
  name: "HomePage",
  components: {
    Logo,
    HomeTitle,
    AdminBoard,
    IconActionCards,
    SequenceList,
  },
  computed: {
    ...mapState(["isAuthenticated"]),
    ...mapGetters("user", ["canEnterAdmin"]),
  },
})
export default class HomePage extends Vue {
  getUF(key: string): string {
    return UFliterals[key] || "";
  }

  get loginCards(): IIconActionCard[] {
    return [
      // {
      //   id: "student-login",
      //   title: "Connexion élève",
      //   icon: "idea_flatline.svg",
      //   action: () =>
      //     this.$router.push({
      //       name: "login",
      //       params: {
      //         student: "student",
      //       },
      //     }),
      // },
      {
        id: "admin-login",
        // title: "Connexion formateur",
        title: "Connexion",
        icon: "idea_flatline.svg",
        // icon: "teacher_flatline.svg",
        action: () =>
          this.$router.push({
            name: "login",
          }),
      },
    ];
  }

  mounted(): void {
    if (!this.isAuthenticated) {
      this.$store.commit("SET_NOTIFICATIONS", []);
    }
  }
}
