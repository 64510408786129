
import { Component, Vue } from "vue-property-decorator";
import { ISequence } from "@/schemas/ISequence";
import { UFliterals } from "@/utils/literals";
import { Roles } from "@/schemas/Enums";
import { mapState } from "vuex";

@Component({
  name: "UserSequenceList",
  computed: {
    ...mapState("user", ["role"]),
  },
})
export default class UserSequenceList extends Vue {
  private sequences: ISequence[] = [];
  private noSequence = false;
  private role: Roles | undefined;
  private interval: any;

  fetchTrainerSequences(): void {
    if (this.role === Roles.Instructor) {
      this.$store.dispatch("sequence/fetchSequences", {
        next: (sequences: ISequence[]) => (this.sequences = sequences),
      });
    }
    if (this.role === Roles.Researcher || this.role === Roles.Admin) {
      this.$store.dispatch("sequence/getMySequences", {
        next: (sequences: ISequence[]) => (this.sequences = sequences),
      });
    }
  }

  fetchSequences(): void {
    this.$store.dispatch(
      "user/fetchUserSequences",
      (sequences: ISequence[]) => (this.sequences = sequences)
    );
  }

  created(): void {
    if (
      this.$store.state.user.role === Roles.Instructor ||
      this.$store.state.user.role === Roles.Researcher ||
      this.$store.state.user.role === Roles.Admin
    ) {
      this.fetchTrainerSequences();
    } else {
      this.fetchSequences();
    }

    // fetch sequences every 3 seconds until the component is destroyed
    this.interval = setInterval(() => {
      if (
        this.$store.state.user.role === Roles.Instructor ||
        this.$store.state.user.role === Roles.Researcher ||
        this.$store.state.user.role === Roles.Admin
      ) {
        this.fetchTrainerSequences();
      } else {
        this.fetchSequences();
      }
    }, 3000);
  }

  destroyed(): void {
    clearInterval(this.interval);
  }

  getUF(key: string): string {
    return UFliterals[key] || "";
  }

  redirectToSequence(sequenceId: string): void {
    this.$router.push({
      name: "sequence",
      params: {
        id: sequenceId,
      },
    });
  }

  get isInstructor(): boolean {
    return (
      this.$store.state.user.role === Roles.Instructor ||
      this.$store.state.user.role === Roles.Admin ||
      this.$store.state.user.role === Roles.Researcher
    );
  }
}
